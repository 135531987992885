import process from 'process';
import React, { useEffect } from 'react';
import {
  Attributes,
  GrowthBook,
  GrowthBookProvider,
} from '@growthbook/growthbook-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import type { AppContext } from 'next/app';
import App from 'next/app';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'animate.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import Router from 'next/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { Provider } from 'react-redux';
import Head from 'next/head';
import { userAgentFromString } from 'next/server';
import Script from 'next/script';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/next';
import cookies from 'js-cookie';
import '../styles/globals.css';
import ErrorBoundary from '../components/ErrorBoundary';
import cmsEndpoints from '../lib/utils/cmsEndpoints';
import { AppWrapper } from '../context/shared';
import store from '../store';
import api from '../lib/utils/api';
import {
  type PopUpData,
  type AppPropsWithData,
  type ConfigDTO,
  type CookieConsentDTO,
  type FooterDTO,
  type Locale,
  type LocalizationDTO,
  type NavbarDTO,
  type NewsletterFormDTO,
  type CmsPopUpDto,
} from '../lib/types';
import { DEFAULT_LOCALE, LOCALES, TRIBU_MAIN_TITLE } from '../lib/constants';
import {
  mapConfigData,
  mapCookieConsentData,
  mapFooterData,
  mapLocalizationData,
  mapNavbarData,
  mapNewsletterFormData,
} from '../lib/utils/mappers';
import { Layout } from '../components/Layout';
import { convergeTracking } from '../lib/utils/converge';
import { mapPopUpData } from '../lib/utils/mappers/map-popup-data';

config.autoAddCss = false;

NProgress.configure({ showSpinner: false });

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const growthbook = new GrowthBook({
  apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
  clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: process.env.NODE_ENV !== 'production',
  subscribeToChanges: true,

  trackingCallback(experiment, result) {
    convergeTracking.trackViewedExperiment(experiment.key, result.key);
  },
});

function updateGrowthBookUrl() {
  growthbook.setURL(window.location.href).catch((error) => {
    console.error('Failed to set GrowthBook URL', error);
  });
}

const getCurrentPath = (currentLocale: Locale, userAttributes: Attributes) => {
  const localePath =
    currentLocale === DEFAULT_LOCALE ? '' : `/${currentLocale}`;

  return `${userAttributes.protocol}//${userAttributes.host}${localePath}${userAttributes.path}`;
};

function MyApp(props: AppPropsWithData) {
  const { Component, pageProps, data, router } = props;
  if (process.env.NODE_ENV === 'production' && typeof window !== 'undefined') {
    console.log = function () {
      return false;
    };
  }

  useEffect(() => {
    growthbook.loadFeatures().catch((error) => {
      console.error('Failed to load GrowthBook features', error);
    });

    const handleRouteChange = () => {
      updateGrowthBookUrl();
      convergeTracking.trackPageLoaded();
    };

    const idCookieName = '_growthbook_id';
    let idCookie = cookies.get(idCookieName);
    if (!idCookie) {
      idCookie = cookies.set(idCookieName, Date.now().toString(), {
        expires: 7,
        sameSite: 'strict',
        secure: true,
      });
    }

    growthbook
      .setAttributes({
        ...data.userAttributes,
        id: idCookie,
      })
      .catch((error) => {
        console.error('Failed to set GrowthBook attributes', error);
      });
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  return (
    <>
      <Head>
        <title>{TRIBU_MAIN_TITLE}</title>
        {Object.values(LOCALES).map((locale) => (
          <link
            key={locale}
            rel="alternate"
            hrefLang={locale}
            href={getCurrentPath(locale, data.userAttributes)}
          />
        ))}
        <link
          rel="alternate"
          hrefLang="x-default"
          href={getCurrentPath(DEFAULT_LOCALE, data.userAttributes)}
        />
      </Head>
      <ErrorBoundary>
        <AppWrapper
          localization={data.localization || {}}
          locale={data.locale}
          config={data.config}
          features={data.features || {}}
        >
          <Provider store={store}>
            <GrowthBookProvider growthbook={growthbook}>
              <Layout
                footer={data.footer}
                navbar={data.navbar}
                newsletter={data.newsletter}
                cookieConsent={data.cookieConsent}
                loginUrl={data.loginUrl}
                statusCode={data.statusCode}
                popUp={data.popUp}
              >
                <Script
                  strategy="lazyOnload"
                  src="https://cdn2.circuly.io/v1/1.8/cart.js"
                />
                <Component {...pageProps} />
                <Analytics />
                <SpeedInsights sampleRate={0.1} />
              </Layout>
            </GrowthBookProvider>
          </Provider>
        </AppWrapper>
      </ErrorBoundary>
    </>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  appContext.ctx?.res?.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59'
  );
  const locale = appContext.ctx.locale ?? LOCALES.en;
  const appProps = await App.getInitialProps(appContext);
  const statusCode = appContext.ctx?.res?.statusCode;

  const userAgentString = appContext.ctx.req?.headers['user-agent'];
  const userAgent = userAgentFromString(userAgentString);

  const request = appContext.ctx?.req;
  const host = request?.headers.host ?? 'localhost';

  const originalUrl = new URL(request?.url ?? '', `https://${host}`);
  const protocol = originalUrl.protocol;

  const urlObject = new URL(`${protocol}//${host}${appContext.ctx.asPath}`);

  const url = urlObject.toString();
  const path = urlObject.pathname;
  const query = urlObject.searchParams.toString();
  const utmSource = urlObject.searchParams.get('utm_source');
  const utmMedium = urlObject.searchParams.get('utm_medium');
  const utmCampaign = urlObject.searchParams.get('utm_campaign');
  const utmTerm = urlObject.searchParams.get('utm_term');
  const utmContent = urlObject.searchParams.get('utm_content');

  const [
    footerData,
    navbarData,
    newsletterData,
    localizationData,
    cookieConsentData,
    configData,
    popUpData,
  ] = await Promise.all([
    api.get(cmsEndpoints.footer()),
    api.get(cmsEndpoints.navbar()),
    api.get(cmsEndpoints.newsletter()),
    api.get(cmsEndpoints.localization()),
    api.get(cmsEndpoints.cookieConsent()),
    api.get(cmsEndpoints.config()),
    api.get(cmsEndpoints.popUp()),
  ]);

  const footer: FooterDTO = mapFooterData(
    footerData?.data.data.attributes,
    locale as Locale
  );
  const navbar: NavbarDTO = mapNavbarData(
    navbarData?.data.data.attributes,
    locale as Locale
  );
  const newsletter: NewsletterFormDTO = mapNewsletterFormData(
    newsletterData?.data.data.attributes,
    locale as Locale
  );
  const localization: LocalizationDTO[] = localizationData?.data?.data?.map(
    (item) => mapLocalizationData(item, locale as Locale)
  );
  const cookieConsent: CookieConsentDTO = mapCookieConsentData(
    cookieConsentData?.data.data.attributes,
    locale as Locale
  );
  const config: ConfigDTO = mapConfigData(
    configData?.data.data.attributes,
    locale as Locale
  );
  const popUp: PopUpData | undefined = mapPopUpData(
    popUpData?.data.data as CmsPopUpDto,
    locale as Locale
  );
  const data = {
    footer,
    navbar,
    newsletter,
    localization,
    locale,
    cookieConsent,
    config,
    statusCode,
    loginUrl: process.env.NEXT_PUBLIC_CUSTOMER_LOGIN_URL ?? '',
    features: {
      prepaidToggle: process.env.FEATURE_PREPAID_TOGGLE === 'true' || false,
    },
    popUp,
    userAttributes: {
      id: Date.now().toString(),
      protocol,
      url,
      path,
      host,
      query,
      deviceType: userAgent.device.type ?? 'desktop',
      browser: userAgent.browser.name,
      utmSource,
      utmMedium,
      utmCampaign,
      utmTerm,
      utmContent,
    },
  };
  return { ...appProps, data };
};

export default MyApp;
